<template>
  <v-card class="pa-10">
    <p class="text-h5 text-center">
      {{ $t("deskAssessment.incomplete.title") }}
    </p>
    <p class="text-body-1 text-center my-4">
      {{ $t("deskAssessment.incomplete.subtitle") }}
    </p>
    <v-card-actions>
      <v-row no-gutters justify="space-around">
        <v-col cols="12" sm="4" md="auto" class="mb-2">
          <v-btn
            id="startExistingButton"
            @click="startExisting"
            outlined
            block
            color="primary"
            >{{ $t("deskAssessment.incomplete.accept") }}
            <v-icon right dark> mdi-arrow-right-circle-outline </v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" sm="4" md="auto" class="mb-2">
          <v-btn
            id="startNewButton"
            @click="startNew"
            outlined
            block
            color="secondary"
            >{{ $t("deskAssessment.incomplete.decline")
            }}<v-icon right dark> mdi-autorenew </v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "IncompleteAssessmentDialog",
  props: {
    incompleteAssessmentId: String
  },
  methods: {
    startNew() {
      this.$emit("start");
      this.$emit("input", false);
    },
    startExisting() {
      this.$router.replace({
        name: "DeskAssessment",
        params: {
          assessmentId: this.incompleteAssessmentId
        }
      });
    }
  }
};
</script>
