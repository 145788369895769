var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "fill-height",
      attrs: { align: "center", "no-gutters": "" }
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-row", { attrs: { justify: "center", "no-gutters": "" } }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 60 60" },
                attrs: {
                  version: "1.1",
                  id: "Capa_1",
                  width: _vm.width,
                  height: _vm.height,
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 60 60",
                  "xml:space": "preserve"
                }
              },
              [
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "pathOffset",
                      attrs: {
                        d:
                          "M59,31h-1v-3c0-0.6-0.4-1-1-1h-1v-7c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v7h-1c-0.6,0-1,0.4-1,1v3h-2V10c0-0.6-0.4-1-1-1\n\t\t\tH33c-0.6,0-1,0.4-1,1v21h-4v-2h-4v2h-4v-4h9c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1H3C2.4,7,2,7.4,2,8v18c0,0.6,0.4,1,1,1h9v4H1\n\t\t\tc-0.6,0-1,0.4-1,1v5v16h2V38h2v15h2V38h48v15h2V38h2v15h2V37v-5C60,31.4,59.6,31,59,31z M50,21h1v3h2v-3h1v6h-4V21z M48,29h1h6h1\n\t\t\tv2h-8V29z M42,11v3h-8v-3H42z M34,16h3v9h2v-9h3v15h-8V16z M28,9v11H4V9H28z M4,22h24v3h-9h-6H4V22z M14,27h4v4h-4V27z M55,36H5H2\n\t\t\tv-3h11h6h14h10h4h10h1v3H55z"
                      }
                    }),
                    _c("rect", {
                      attrs: { x: "37", y: "27", width: "2", height: "2" }
                    })
                  ])
                ])
              ]
            )
          ]),
          _c("p", { staticClass: "text-center text-h4 mb-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("deskAssessment.loadingMessage")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }