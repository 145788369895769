var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.sendReportDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": _vm.sendReportDialogSize },
              model: {
                value: _vm.sendReportDialog,
                callback: function($$v) {
                  _vm.sendReportDialog = $$v
                },
                expression: "sendReportDialog"
              }
            },
            [
              _c("SendReportDialog", {
                attrs: {
                  assessmentID: _vm.assessmentId,
                  assessmentType: "Desk assessment"
                },
                on: {
                  inputClose: function($event) {
                    _vm.sendReportDialog = false
                  }
                },
                model: {
                  value: _vm.sendReportDialog,
                  callback: function($$v) {
                    _vm.sendReportDialog = $$v
                  },
                  expression: "sendReportDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showBack,
                  expression: "showBack"
                }
              ],
              attrs: { id: "backButtonContainer", sm: "6", md: "2" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "backButton",
                    color: "primary",
                    large: "",
                    outlined: "",
                    rounded: "",
                    width: "100%"
                  },
                  on: { click: _vm.back }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.back")))]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showNext,
                  expression: "showNext"
                }
              ],
              attrs: { id: "nextButtonContainer", sm: "6", md: "2" }
            },
            [
              _vm.currentPage > 0 && !_vm.showMeasureButton
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "nextButton",
                        loading: _vm.savingData,
                        color: "primary",
                        large: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: { click: _vm.next }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                  )
                : _vm._e(),
              _vm.currentPageIsFirstPage
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "startButton",
                        disabled: _vm.disableStart,
                        color: "primary",
                        large: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: { click: _vm.next }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.start")))]
                  )
                : _vm._e(),
              _vm.showMeasureButton
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.disableMeasure,
                        color: "primary",
                        large: "",
                        rounded: "",
                        width: "100%"
                      },
                      on: { click: _vm.measure }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.measure")))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSkip,
                      expression: "showSkip"
                    }
                  ],
                  attrs: {
                    color: "primary",
                    text: "",
                    large: "",
                    width: "100%"
                  },
                  on: { click: _vm.skip }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.skip")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }