<template>
  <v-row align="center" class="fill-height" no-gutters>
    <v-col cols="12">
      <v-row justify="center" no-gutters>
        <svg
          version="1.1"
          id="Capa_1"
          :width="width"
          :height="height"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 60 60"
          style="enable-background: new 0 0 60 60"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                class="pathOffset"
                d="M59,31h-1v-3c0-0.6-0.4-1-1-1h-1v-7c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v7h-1c-0.6,0-1,0.4-1,1v3h-2V10c0-0.6-0.4-1-1-1
			H33c-0.6,0-1,0.4-1,1v21h-4v-2h-4v2h-4v-4h9c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1H3C2.4,7,2,7.4,2,8v18c0,0.6,0.4,1,1,1h9v4H1
			c-0.6,0-1,0.4-1,1v5v16h2V38h2v15h2V38h48v15h2V38h2v15h2V37v-5C60,31.4,59.6,31,59,31z M50,21h1v3h2v-3h1v6h-4V21z M48,29h1h6h1
			v2h-8V29z M42,11v3h-8v-3H42z M34,16h3v9h2v-9h3v15h-8V16z M28,9v11H4V9H28z M4,22h24v3h-9h-6H4V22z M14,27h4v4h-4V27z M55,36H5H2
			v-3h11h6h14h10h4h10h1v3H55z"
              />
              <rect x="37" y="27" width="2" height="2" />
            </g>
          </g>
        </svg>
      </v-row>
      <p class="text-center text-h4 mb-0">
        {{ $t("deskAssessment.loadingMessage") }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DeskLoader",
  props: {
    width: Number,
    height: Number
  }
};
</script>

<style scoped>
.pathOffset {
  stroke: #ffc300;
  stroke-width: 1px;
  stroke-dashoffset: -500;
  stroke-dasharray: 500;
  animation-name: pathOffset;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes pathOffset {
  0% {
    stroke-dashoffset: -500;
    stroke: var(--v-accent-base);
  }
  50% {
    stroke: var(--v-accent-base);
  }
  70% {
    stroke: var(--v-accent-base);
  }
  100% {
    stroke-dashoffset: 0;
    stroke: var(--v-primary-base);
  }
}
</style>
