var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-slide-y-reverse-transition",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: false,
              expression: "false"
            }
          ],
          staticClass: "fabContainer",
          attrs: {
            bottom: "",
            right: "",
            fixed: "",
            fab: "",
            color: "transparent",
            depressed: ""
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between align-end" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "text-h5 text-none mb-0",
                          staticStyle: { "max-height": "40px" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.ratingSubmitted
                                  ? _vm.$t("feedback.shortFeedbackTitle.text")
                                  : _vm.$t("feedback.shortFeedbackTitle.rating")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/images/physioProfile.png"),
                          "max-height": "70px",
                          "max-width": "70px",
                          contain: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "emojiContainer pa-1",
                      attrs: {
                        "max-width": "400px",
                        color: _vm.feedbackColor,
                        shaped: ""
                      }
                    },
                    [
                      !_vm.ratingSubmitted
                        ? _c("feedback-emojis", {
                            attrs: { showLabels: false },
                            on: {
                              input: function($event) {
                                return _vm.submitFeedback()
                              },
                              hover: function($event) {
                                _vm.hoverEmoji = $event
                              }
                            },
                            model: {
                              value: _vm.reaction,
                              callback: function($$v) {
                                _vm.reaction = $$v
                              },
                              expression: "reaction"
                            }
                          })
                        : _vm._e(),
                      _vm.ratingSubmitted
                        ? _c("v-textarea", {
                            ref: "textInput",
                            staticClass: "text-area",
                            attrs: {
                              outlined: "",
                              label: "",
                              "auto-grow": "",
                              "hide-details": "",
                              filled: "",
                              "no-resize": "",
                              "background-color": "white",
                              color: "white"
                            },
                            model: {
                              value: _vm.textFeedback,
                              callback: function($$v) {
                                _vm.textFeedback = $$v
                              },
                              expression: "textFeedback"
                            }
                          })
                        : _vm._e(),
                      _vm.ratingSubmitted
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                disabled: _vm.sending,
                                loading: _vm.sending,
                                id: "sendTextFeedbackButton",
                                outlined: "",
                                color: "white"
                              },
                              on: { click: _vm.addTextToFeedback }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.submit")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }