var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { width: "90%" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "space-around" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-4",
                            style:
                              "height:100%; border: 2px solid " +
                              _vm.getBorderColor(_vm.checkedStages[0]) +
                              "!important",
                            attrs: {
                              elevation: hover ? 16 : 2,
                              color: _vm.checkedStages[0]
                                ? "selectedPrimary"
                                : "transparent",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.stepClicked(0)
                              }
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "container ma-0",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/webcamOnScreen.png"),
                                    height: "200px",
                                    contain: ""
                                  }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "topRightFloat",
                                  on: {
                                    click: function($event) {
                                      return _vm.stepClicked(0)
                                    }
                                  },
                                  model: {
                                    value: _vm.checkedStages[0],
                                    callback: function($$v) {
                                      _vm.$set(_vm.checkedStages, 0, $$v)
                                    },
                                    expression: "checkedStages[0]"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-h4 text-center vitrueGrey-color"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step1Title"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "text-body-2 text-center px-3 py-2"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step1"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-4",
                            style:
                              "height:100%; border: 2px solid " +
                              _vm.getBorderColor(_vm.checkedStages[1]) +
                              "!important",
                            attrs: {
                              elevation: hover ? 16 : 2,
                              color: _vm.checkedStages[1]
                                ? "selectedPrimary"
                                : "transparent",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.stepClicked(1)
                              }
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "container ma-0",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/90degreesScreen.png"),
                                    height: "200px",
                                    contain: ""
                                  }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "topRightFloat",
                                  on: {
                                    click: function($event) {
                                      return _vm.stepClicked(1)
                                    }
                                  },
                                  model: {
                                    value: _vm.checkedStages[1],
                                    callback: function($$v) {
                                      _vm.$set(_vm.checkedStages, 1, $$v)
                                    },
                                    expression: "checkedStages[1]"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-h4 text-center vitrueGrey-color"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step2Title"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "text-body-2 text-center px-3" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step2"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-4",
                            style:
                              "height:100%; border: 2px solid " +
                              _vm.getBorderColor(_vm.checkedStages[2]) +
                              "!important",
                            attrs: {
                              elevation: hover ? 16 : 2,
                              color: _vm.checkedStages[2]
                                ? "selectedPrimary"
                                : "transparent",
                              outlined: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.stepClicked(2)
                              }
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "container ma-0",
                                attrs: { justify: "center" }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/sittingPosition.png"),
                                    height: "200px",
                                    contain: ""
                                  }
                                }),
                                _c("v-checkbox", {
                                  staticClass: "topRightFloat",
                                  on: {
                                    click: function($event) {
                                      return _vm.stepClicked(2)
                                    }
                                  },
                                  model: {
                                    value: _vm.checkedStages[2],
                                    callback: function($$v) {
                                      _vm.$set(_vm.checkedStages, 2, $$v)
                                    },
                                    expression: "checkedStages[2]"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-h4 text-center vitrueGrey-color"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step3Title"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "text-body-2 text-center px-3" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "deskAssessment.webcam.positioning.step3"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }