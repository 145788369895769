var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    {
      attrs: { flat: "", multiple: "", accordion: "", tile: "" },
      model: {
        value: _vm.painAreaOpenPanels,
        callback: function($$v) {
          _vm.painAreaOpenPanels = $$v
        },
        expression: "painAreaOpenPanels"
      }
    },
    _vm._l(_vm.currentPainAreas, function(area) {
      return _c(
        "v-expansion-panel",
        { key: area, staticClass: "mb-2 pa-0" },
        [
          _c(
            "v-expansion-panel-header",
            { staticClass: "py-0 pl-2", attrs: { color: "vitruePaleGrey" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "py-0",
                  attrs: { "no-gutters": "", justify: "space-between" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-h6", attrs: { id: area + "Name" } },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            justify: "start\n          "
                          }
                        },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "mr-2",
                                staticStyle: { width: "24px" }
                              },
                              [
                                _vm.isAreaCompleted(area)
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          id: area + "CompleteIcon",
                                          color: "secondary"
                                        }
                                      },
                                      [_vm._v("mdi-check")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_vm.getNameFromSvgRegion(area)))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            id: area + "DeleteButton",
                            icon: "",
                            small: ""
                          },
                          on: {
                            click: function(event) {
                              _vm.removePanel(event, area)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-trash-can-outline")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel-content",
            [
              _c("form-generator", {
                key: area + "Form",
                attrs: {
                  questions: _vm.painPage.page.questions[area],
                  fullResults: _vm.results
                },
                model: {
                  value: _vm.results["pain"].areas[area],
                  callback: function($$v) {
                    _vm.$set(_vm.results["pain"].areas, area, $$v)
                  },
                  expression: "results['pain'].areas[area]"
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }