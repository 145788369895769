<template>
  <div>
    <v-card
      id="focusAreasCard"
      class="px-3"
      v-show="showFocusedAreaPage"
      rounded
      style="overflow-x: hidden"
    >
      <v-row justify="end" no-gutters style="min-height: 50px">
        <LanguageSelection class="ma-3" />
      </v-row>

      <v-row justify="center" no-gutters>
        <v-col sm="10">
          <v-row class="text-center" justify="end" no-gutters>
            <v-col cols="12"
              ><p id="titleText" class="vitrueGrey--text" :class="titleSize">
                {{ $t("onboarding.endUserFocusArea.title") }}
              </p>
              <p
                id="subtitleText"
                class="vitrueGrey--text"
                :class="subtextSize"
              >
                {{ $t("onboarding.endUserFocusArea.subtitle") }}
              </p>
              <p :class="subtextSize" class="font-weight-bold">
                {{ $t("onboarding.endUserFocusArea.question") }}
              </p>
            </v-col>
            <select-goal @onGoalSelected="onGoalSelected" />
            <v-col cols="12" style="min-height: 30px" class="mt-3">
              <v-btn
                v-if="selectedFocusArea >= 0 && selectedFocusArea < 3"
                id="focusAreasNextButton"
                @click="showFocusedAreaPage = false"
                color="primary"
                rounded
                :small="highZoom"
                min-width="200px"
                >{{ $t("buttons.next") }}</v-btn
              >
            </v-col>
            <v-col cols="12" class="mb-2">
              <v-btn
                id="skipTourButton"
                :small="highZoom"
                text
                @click="closeTour()"
                >{{ $t("buttons.skipTour") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0">
          <p
            class="text-caption font-weight-light text-center mb-2"
            v-html="$t('welcomeToAssessment.termsOfService')"
          ></p>
        </v-col>
      </v-row>
    </v-card>
    <onboarding-tour
      v-show="!showFocusedAreaPage"
      showBackOnFirstPage
      :pages="onboardingTourPages"
      @tourComplete="closeTour()"
      @closed="closeTour()"
      @backOnFirstPage="showFocusedAreaPage = true"
      @page="tourPage = $event"
    />
  </div>
</template>

<script>
import SelectGoal from "@/components/wellness/SelectGoal";
import OnboardingTour from "@/components/account/onboarding/OnboardingTour";
import LanguageSelection from "@/components/common/LanguageSelection.vue";
import { mapMutations } from "vuex";
import { FocusArea } from "@/services/deskassessment/focus-areas";
import { updateUserGoal } from "@/customApi";

export default {
  name: "EndUserTour",
  components: {
    SelectGoal,
    OnboardingTour,
    LanguageSelection
  },
  props: {
    userId: String,
    assessmentId: String,
    goalSet: Boolean
  },
  data() {
    return {
      showFocusedAreaPage: !this.goalSet,
      selectedFocusArea: -1,
      tourPage: 0
    };
  },
  mounted() {
    this.openModal();
  },
  computed: {
    onboardingTourPages() {
      return [
        {
          title: this.$t("onboarding.endUserTour.1.title"),
          subText: this.$t("onboarding.endUserTour.1.subtext"),
          imageUrl: "webcam.gif"
        },
        {
          title: this.$t("onboarding.endUserTour.2.title"),
          subText: this.$t("onboarding.endUserTour.2.subtext"),
          imageUrl: "recommendations.png"
        },
        {
          title: this.$t("onboarding.endUserTour.3.title"),
          subText: this.$t("onboarding.endUserTour.3.subtext"),
          imageUrl: "painCoach.gif"
        }
      ];
    },
    isHighZoomOrSmallScreen() {
      return this.highZoom || this.$vuetify.breakpoint.smAndDown;
    },
    titleSize() {
      return this.isHighZoomOrSmallScreen ? "text-h5" : "text-h4 mb-10";
    },
    subtextSize() {
      return this.isHighZoomOrSmallScreen ? "text-body-1" : "text-h6 mb-12";
    },
    highZoom() {
      return window.devicePixelRatio > 1;
    }
  },
  beforeDestroy() {
    this.logStageOfOnboardingReached();
    this.closeModal();
  },
  methods: {
    ...mapMutations(["openModal", "closeModal"]),
    async closeTour() {
      this.$emit("input", false);
    },
    async onGoalSelected(goal) {
      this.selectedFocusArea = goal;
      let userGoal = FocusArea.fromIntValue(goal).stringValue;
      let dto = {
        userId: this.userId,
        goal: userGoal,
        isDefault: false,
        assessmentId: this.assessmentId
      };
      try {
        await updateUserGoal(dto);
      } catch (ex) {
        // sentry message sent in rest api client
      }
      this.$emit("updatedGoal", userGoal);
    },
    logStageOfOnboardingReached() {
      var page = this.showFocusedAreaPage ? 0 : this.tourPage + 1;
      this.$mixpanel.track("Desk assessment onboarding", { page: page });
      this.$gtag.event(`Stopped at page: ${page}`, {
        event_category: "Assessment Onboard"
      });
    }
  }
};
</script>
