var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-5" },
    [
      _c("v-card-title", { staticClass: "text-h4 justify-center" }, [
        _vm._v(" Quick Results ")
      ]),
      _c(
        "v-row",
        { attrs: { justify: "space-between" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-combobox", {
                staticClass: "mb-3",
                attrs: {
                  items: _vm.allConfigs,
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Overall"
                },
                on: {
                  input: function($event) {
                    return _vm.updateResults()
                  }
                },
                model: {
                  value: _vm.allResultsConfig,
                  callback: function($$v) {
                    _vm.allResultsConfig = $$v
                  },
                  expression: "allResultsConfig"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.printResultToConsole }
                },
                [_c("v-icon", [_vm._v(" mdi-printer ")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        { staticClass: "mb-2", attrs: { flat: "", accordion: "", tile: "" } },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "mb-2" },
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { color: "grey" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [_vm._v(" Webcam ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.configs,
                              "hide-details": "",
                              dense: "",
                              dark: "",
                              outlined: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateSectionResults(
                                  "webcam",
                                  _vm.panelConfigs.webcam
                                )
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.panelConfigs.webcam,
                              callback: function($$v) {
                                _vm.$set(_vm.panelConfigs, "webcam", $$v)
                              },
                              expression: "panelConfigs.webcam"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-8 pb-5" },
                [
                  _c("v-slider", {
                    attrs: {
                      max: 90,
                      min: 20,
                      label: "Screen distance",
                      "thumb-label": "always"
                    },
                    on: { input: _vm.updateWebcam },
                    model: {
                      value: _vm.results.webcam.distanceFromScreen,
                      callback: function($$v) {
                        _vm.$set(_vm.results.webcam, "distanceFromScreen", $$v)
                      },
                      expression: "results.webcam.distanceFromScreen"
                    }
                  }),
                  _c("v-slider", {
                    attrs: {
                      max: 20,
                      min: -20,
                      label: "Screen height angle",
                      "thumb-label": "always"
                    },
                    on: { input: _vm.updateWebcam },
                    model: {
                      value: _vm.results.webcam.screenHeightAngle,
                      callback: function($$v) {
                        _vm.$set(_vm.results.webcam, "screenHeightAngle", $$v)
                      },
                      expression: "results.webcam.screenHeightAngle"
                    }
                  }),
                  _c("v-slider", {
                    attrs: {
                      max: 8,
                      min: 0,
                      label: "Shoulder height difference",
                      "thumb-label": "always"
                    },
                    on: { input: _vm.updateWebcam },
                    model: {
                      value: _vm.results.webcam.shoulderHeightDifference,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.results.webcam,
                          "shoulderHeightDifference",
                          $$v
                        )
                      },
                      expression: "results.webcam.shoulderHeightDifference"
                    }
                  }),
                  _c("v-slider", {
                    attrs: {
                      max: 30,
                      min: 0,
                      label: "Shoulder to ear difference",
                      "thumb-label": "always"
                    },
                    on: { input: _vm.updateWebcam },
                    model: {
                      value: _vm.results.webcam.shoulderToEarDifference,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.results.webcam,
                          "shoulderToEarDifference",
                          $$v
                        )
                      },
                      expression: "results.webcam.shoulderToEarDifference"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            { staticClass: "mb-2" },
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { color: "grey" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [_vm._v(" Pain ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.configs,
                              "hide-details": "",
                              dense: "",
                              dark: "",
                              outlined: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateSectionResults(
                                  "pain",
                                  _vm.panelConfigs.pain
                                )
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.panelConfigs.pain,
                              callback: function($$v) {
                                _vm.$set(_vm.panelConfigs, "pain", $$v)
                              },
                              expression: "panelConfigs.pain"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-8 pb-5" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "align-self-start",
                      attrs: { justify: "space-around" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "4" } },
                        [
                          _c("pain-person", {
                            ref: "painPerson",
                            attrs: {
                              validateForm: _vm.validateForm,
                              linkedToQuestions: true,
                              assessmentType: "deskAssessment"
                            },
                            model: {
                              value: _vm.results.pain,
                              callback: function($$v) {
                                _vm.$set(_vm.results, "pain", $$v)
                              },
                              expression: "results.pain"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-5",
                          attrs: { cols: "12", sm: "10", md: "6" }
                        },
                        [
                          _c("pain-question-panels", {
                            ref: "painAreaPanels",
                            attrs: { results: _vm.results },
                            on: {
                              removePainArea: function($event) {
                                return _vm.$refs.painPerson.confirmRemovePainArea(
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            { staticClass: "mb-2" },
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { color: "grey" }, on: { click: _vm.clickedSims } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [_vm._v(" Sims ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.configs,
                              "hide-details": "",
                              dense: "",
                              dark: "",
                              outlined: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateSimsResults()
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.panelConfigs.sims,
                              callback: function($$v) {
                                _vm.$set(_vm.panelConfigs, "sims", $$v)
                              },
                              expression: "panelConfigs.sims"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-8 pb-5", attrs: { eager: "" } },
                [
                  _vm.showSims
                    ? _c("sims", {
                        attrs: { reloadOnUpdate: "" },
                        on: {
                          updateSavedSimsValues: function($event) {
                            _vm.savedSimsValues = $event
                          }
                        },
                        model: {
                          value: _vm.results.setup,
                          callback: function($$v) {
                            _vm.$set(_vm.results, "setup", $$v)
                          },
                          expression: "results.setup"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            { staticClass: "mb-2" },
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { color: "grey" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [_vm._v(" Setup ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.configs,
                              "hide-details": "",
                              dense: "",
                              dark: "",
                              outlined: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateSectionResults(
                                  "setup",
                                  _vm.panelConfigs.setup
                                )
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.panelConfigs.setup,
                              callback: function($$v) {
                                _vm.$set(_vm.panelConfigs, "setup", $$v)
                              },
                              expression: "panelConfigs.setup"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-8 pb-5" },
                [
                  _c("form-generator", {
                    attrs: {
                      questions: _vm.setupQuestions,
                      fullResults: _vm.results,
                      answersToKeep: _vm.savedSimsValues
                    },
                    model: {
                      value: _vm.results.setup,
                      callback: function($$v) {
                        _vm.$set(_vm.results, "setup", $$v)
                      },
                      expression: "results.setup"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                { attrs: { color: "grey" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text font-weight-bold",
                          attrs: { cols: "auto", "align-self": "center" }
                        },
                        [_vm._v(" Health ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.configs,
                              "hide-details": "",
                              dense: "",
                              dark: "",
                              outlined: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateSectionResults(
                                  "health",
                                  _vm.panelConfigs.health
                                )
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            },
                            model: {
                              value: _vm.panelConfigs.health,
                              callback: function($$v) {
                                _vm.$set(_vm.panelConfigs, "health", $$v)
                              },
                              expression: "panelConfigs.health"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                { staticClass: "pt-8 pb-5" },
                [
                  _c("form-generator", {
                    attrs: {
                      questions: _vm.healthQuestions,
                      fullResults: _vm.results
                    },
                    model: {
                      value: _vm.results.health,
                      callback: function($$v) {
                        _vm.$set(_vm.results, "health", $$v)
                      },
                      expression: "results.health"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("report")
                    }
                  }
                },
                [_vm._v(" Go to report ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }