<template>
  <v-slide-y-reverse-transition>
    <v-btn
      class="fabContainer"
      v-ripple="false"
      bottom
      right
      fixed
      fab
      color="transparent"
      depressed
    >
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-end">
            <p class="text-h5 text-none mb-0" style="max-height: 40px">
              {{
                ratingSubmitted
                  ? $t("feedback.shortFeedbackTitle.text")
                  : $t("feedback.shortFeedbackTitle.rating")
              }}
            </p>
            <v-img
              :src="require('@/assets/images/physioProfile.png')"
              max-height="70px"
              max-width="70px"
              contain
            />
          </div>
          <v-card
            class="emojiContainer pa-1"
            max-width="400px"
            :color="feedbackColor"
            shaped
          >
            <feedback-emojis
              v-if="!ratingSubmitted"
              v-model="reaction"
              :showLabels="false"
              @input="submitFeedback()"
              @hover="hoverEmoji = $event"
            />
            <v-textarea
              v-if="ratingSubmitted"
              ref="textInput"
              outlined
              label
              v-model="textFeedback"
              auto-grow
              hide-details
              filled
              no-resize
              class="text-area"
              background-color="white"
              color="white"
            >
            </v-textarea>
            <v-btn
              v-if="ratingSubmitted"
              @click="addTextToFeedback"
              :disabled="sending"
              :loading="sending"
              id="sendTextFeedbackButton"
              outlined
              color="white"
              class="mt-1"
            >
              {{ $t("buttons.submit") }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-btn>
  </v-slide-y-reverse-transition>
</template>

<script>
import FeedbackEmojis from "@/components/common/Feedback/FeedbackEmojis.vue";
import { mapGetters, mapMutations } from "vuex";
import { createFeedback, updateFeedback } from "@/customApi";

export default {
  name: "short-feedback",
  components: {
    FeedbackEmojis
  },
  data() {
    return {
      reaction: null,
      hoverEmoji: null,
      textFeedback: "",
      feedbackId: null,
      sending: false
    };
  },
  props: {
    assessmentId: String
  },
  computed: {
    ...mapGetters(["userEmail"]),
    feedbackColor() {
      return this.reaction ? this.reaction.color : this.hoverEmoji?.color;
    },
    feedbackDto() {
      return {
        Id: this.feedbackId,
        AssessmentId: this.assessmentId,
        Email: { value: this.userEmail },
        Score: this.reaction.value,
        WrittenFeedback: this.textFeedback,
        Url: this.$route.path
      };
    },
    ratingSubmitted() {
      return !!this.feedbackId;
    }
  },
  methods: {
    ...mapMutations(["addAsyncRequest", "removeAsyncRequest"]),
    async submitFeedback() {
      if (this.reaction) {
        try {
          this.sending = true;
          this.feedbackId = await createFeedback(this.feedbackDto);
          this.$gtag.event("Submit Short Feedback", {
            event_category: "Feedback"
          });
        } catch (err) {
          this.$logger.captureException(error);
        } finally {
          this.sending = false;
        }
      }
    },
    async addTextToFeedback() {
      if (this.feedbackDto.Id) {
        try {
          this.sending = true;
          await updateFeedback(this.feedbackDto);
          this.$gtag.event("Add text to Short Feedback", {
            event_category: "Feedback"
          });
        } catch (err) {
          this.$logger.captureException(error);
        } finally {
          this.sending = false;
          this.$emit("input", true);
        }
      }
    }
  }
};
</script>

<style scoped>
.emojiContainer {
  transition: background-color 1s;
}
.fabContainer:before {
  display: none;
}

.text-area {
  width: 370px;
  height: 130px;
  overflow-y: auto;
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
