var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 mx-auto mb-8", attrs: { color: "grey lighten-3" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { left: "", color: "yellow darken-2" } },
                    [_vm._v("mdi-lightbulb")]
                  )
                ],
                1
              ),
              _c("v-col", { staticClass: "text-body-2 font-weight-light" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("deskAssessment.health.confidentialityText")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }