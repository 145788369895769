var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-10" },
    [
      _c("p", { staticClass: "text-h5 text-center" }, [
        _vm._v(" " + _vm._s(_vm.$t("deskAssessment.incomplete.title")) + " ")
      ]),
      _c("p", { staticClass: "text-body-1 text-center my-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("deskAssessment.incomplete.subtitle")) + " ")
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-around" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "mb-2",
                  attrs: { cols: "12", sm: "4", md: "auto" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "startExistingButton",
                        outlined: "",
                        block: "",
                        color: "primary"
                      },
                      on: { click: _vm.startExisting }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("deskAssessment.incomplete.accept")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-arrow-right-circle-outline ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "mb-2",
                  attrs: { cols: "12", sm: "4", md: "auto" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        id: "startNewButton",
                        outlined: "",
                        block: "",
                        color: "secondary"
                      },
                      on: { click: _vm.startNew }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("deskAssessment.incomplete.decline"))
                      ),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v(" mdi-autorenew ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }