var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFocusedAreaPage,
              expression: "showFocusedAreaPage"
            }
          ],
          staticClass: "px-3",
          staticStyle: { "overflow-x": "hidden" },
          attrs: { id: "focusAreasCard", rounded: "" }
        },
        [
          _c(
            "v-row",
            {
              staticStyle: { "min-height": "50px" },
              attrs: { justify: "end", "no-gutters": "" }
            },
            [_c("LanguageSelection", { staticClass: "ma-3" })],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { sm: "10" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-center",
                      attrs: { justify: "end", "no-gutters": "" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "vitrueGrey--text",
                            class: _vm.titleSize,
                            attrs: { id: "titleText" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("onboarding.endUserFocusArea.title")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "vitrueGrey--text",
                            class: _vm.subtextSize,
                            attrs: { id: "subtitleText" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("onboarding.endUserFocusArea.subtitle")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "font-weight-bold",
                            class: _vm.subtextSize
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("onboarding.endUserFocusArea.question")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("select-goal", {
                        on: { onGoalSelected: _vm.onGoalSelected }
                      }),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-3",
                          staticStyle: { "min-height": "30px" },
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.selectedFocusArea >= 0 &&
                          _vm.selectedFocusArea < 3
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "focusAreasNextButton",
                                    color: "primary",
                                    rounded: "",
                                    small: _vm.highZoom,
                                    "min-width": "200px"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showFocusedAreaPage = false
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mb-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "skipTourButton",
                                small: _vm.highZoom,
                                text: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeTour()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.skipTour")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-col", { staticClass: "py-0", attrs: { cols: "12" } }, [
                _c("p", {
                  staticClass:
                    "text-caption font-weight-light text-center mb-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("welcomeToAssessment.termsOfService")
                    )
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("onboarding-tour", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showFocusedAreaPage,
            expression: "!showFocusedAreaPage"
          }
        ],
        attrs: { showBackOnFirstPage: "", pages: _vm.onboardingTourPages },
        on: {
          tourComplete: function($event) {
            return _vm.closeTour()
          },
          closed: function($event) {
            return _vm.closeTour()
          },
          backOnFirstPage: function($event) {
            _vm.showFocusedAreaPage = true
          },
          page: function($event) {
            _vm.tourPage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }