<template>
  <v-card class="pa-5">
    <v-card-title class="text-h4 justify-center"> Quick Results </v-card-title>
    <v-row justify="space-between">
      <v-col cols="4">
        <v-combobox
          class="mb-3"
          v-model="allResultsConfig"
          :items="allConfigs"
          hide-details
          dense
          @input="updateResults()"
          outlined
          label="Overall"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn icon @click="printResultToConsole">
          <v-icon> mdi-printer </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-expansion-panels flat accordion tile class="mb-2">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header color="grey">
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="white--text font-weight-bold"
            >
              Webcam
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="panelConfigs.webcam"
                :items="configs"
                hide-details
                dense
                @input="updateSectionResults('webcam', panelConfigs.webcam)"
                dark
                @click.native.stop
                outlined
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-8 pb-5">
          <v-slider
            v-model="results.webcam.distanceFromScreen"
            :max="90"
            :min="20"
            label="Screen distance"
            thumb-label="always"
            @input="updateWebcam"
          />
          <v-slider
            v-model="results.webcam.screenHeightAngle"
            :max="20"
            :min="-20"
            label="Screen height angle"
            thumb-label="always"
            @input="updateWebcam"
          />
          <v-slider
            v-model="results.webcam.shoulderHeightDifference"
            :max="8"
            :min="0"
            label="Shoulder height difference"
            thumb-label="always"
            @input="updateWebcam"
          />
          <v-slider
            v-model="results.webcam.shoulderToEarDifference"
            :max="30"
            :min="0"
            label="Shoulder to ear difference"
            thumb-label="always"
            @input="updateWebcam"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header color="grey">
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="white--text font-weight-bold"
            >
              Pain
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="panelConfigs.pain"
                :items="configs"
                hide-details
                dense
                @input="updateSectionResults('pain', panelConfigs.pain)"
                dark
                @click.native.stop
                outlined
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-8 pb-5">
          <v-row class="align-self-start" justify="space-around">
            <v-col cols="12" md="6" lg="4">
              <pain-person
                ref="painPerson"
                v-model="results.pain"
                :validateForm="validateForm"
                :linkedToQuestions="true"
                assessmentType="deskAssessment"
              />
            </v-col>

            <v-col cols="12" sm="10" md="6" class="pa-5">
              <pain-question-panels
                ref="painAreaPanels"
                :results="results"
                @removePainArea="$refs.painPerson.confirmRemovePainArea($event)"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header color="grey" @click="clickedSims">
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="white--text font-weight-bold"
            >
              Sims
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="panelConfigs.sims"
                :items="configs"
                hide-details
                dense
                @input="updateSimsResults()"
                dark
                @click.native.stop
                outlined
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-8 pb-5" eager>
          <sims
            v-if="showSims"
            v-model="results.setup"
            @updateSavedSimsValues="savedSimsValues = $event"
            reloadOnUpdate
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header color="grey">
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="white--text font-weight-bold"
            >
              Setup
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="panelConfigs.setup"
                :items="configs"
                hide-details
                dense
                @input="updateSectionResults('setup', panelConfigs.setup)"
                dark
                @click.native.stop
                outlined
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-8 pb-5">
          <form-generator
            :questions="setupQuestions"
            :fullResults="results"
            v-model="results.setup"
            :answersToKeep="savedSimsValues"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header color="grey">
          <v-row>
            <v-col
              cols="auto"
              align-self="center"
              class="white--text font-weight-bold"
            >
              Health
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="panelConfigs.health"
                :items="configs"
                hide-details
                dense
                @input="updateSectionResults('health', panelConfigs.health)"
                dark
                @click.native.stop
                outlined
              />
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-8 pb-5">
          <form-generator
            :questions="healthQuestions"
            :fullResults="results"
            v-model="results.health"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn @click="$emit('report')" color="primary"> Go to report </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import FakeResults from "@/assets/json/Debug/ResultConfigs.json";
import Questions from "@/assets/json/DeskAssessment/DeskAssessmentQuestions.json";
import FormGenerator from "@/components/form/FormGenerator.vue";
import Sims from "@/components/deskassessment/Sims.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import PainQuestionPanels from "@/components/deskassessment/PainQuestionPanels";

export default {
  name: "QuickLoadAssessment",
  components: {
    FormGenerator,
    Sims,
    PainPerson,
    PainQuestionPanels
  },
  props: {
    value: Object,
    validateForm: Function
  },
  data() {
    return {
      results: this.value,
      configs: ["-", "Bad", "Average", "Good"],
      configMapping: {
        Bad: "Terrible Result",
        Average: "Average Result",
        Good: "Perfect Result"
      },
      allResultsConfig: Object.keys(FakeResults)[0],
      panelConfigs: {
        webcam: "-",
        setup: "-",
        sims: "-",
        pain: "-",
        health: "-"
      },
      savedSimsValues: [],
      showSims: false
    };
  },
  watch: {
    value: {
      handler() {
        this.results = this.value;
      },
      deep: true
    }
  },
  computed: {
    allConfigs() {
      return Object.keys(FakeResults);
    },
    setupQuestions() {
      let setup = Questions.find(x => x.theme === "setup");
      let questions = setup.pages.map(x => x.questions).flat();
      return questions.filter(x => !!x.question);
    },
    healthQuestions() {
      let health = Questions.find(x => x.theme === "health");
      let questions = health.pages.map(x => x.questions).flat();
      return questions.filter(x => !!x.question);
    }
  },
  methods: {
    updateWebcam() {
      this.results.webcam.pictureTaken = true;
      this.results.webcam.capturedImage = "image";
    },
    updateResults() {
      this.results = { ...FakeResults[this.allResultsConfig] };
      this.resetConfigs();
      this.update();
    },
    resetConfigs() {
      var config = "-";
      Object.keys(this.configMapping).forEach(key => {
        if (this.configMapping[key] === this.allResultsConfig) {
          config = key;
        }
      });

      Object.keys(this.panelConfigs).forEach(key => {
        this.panelConfigs[key] = config;
      });
    },
    updateSectionResults(section, config) {
      if (config === "-") {
        return;
      }
      let name = this.configMapping[config];
      let results = { ...FakeResults[name][section] };
      this.results[section] = results;
      this.update();
    },
    updateSimsResults() {
      if (this.panelConfigs.sims === "-") {
        return;
      }
      let name = this.configMapping[this.panelConfigs.sims];
      let items = [...FakeResults[name].setup.deskItems];
      this.results.setup.deskItems = items;
      this.update();
    },
    update() {
      this.$emit("input", this.results);
    },
    printResultToConsole() {
      let result = { ...this.results };
      result.webcam.capturedImage = "placeholder";
      if (result.setup.deskItems) {
        result.setup.deskItems.forEach(item => delete item.imageSource);
      }
      console.log(JSON.stringify(result));
    },
    async clickedSims() {
      await this.delay(200);
      this.showSims = true;
    },
    async delay(ms) {
      return new Promise(async resolve => await setTimeout(resolve, ms));
    }
  }
};
</script>
