<template>
  <v-card color="grey lighten-3" class="px-5 mx-auto mb-8">
    <v-col>
      <v-row align="center" no-gutters>
        <v-col cols="auto"
          ><v-icon left color="yellow darken-2">mdi-lightbulb</v-icon>
        </v-col>
        <v-col class="text-body-2 font-weight-light">
          {{ $t("deskAssessment.health.confidentialityText") }}
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: "HealthConfidentialityCard"
};
</script>
