<template>
  <div class="fill-height">
    <desk-loader
      v-if="reportLoading"
      id="reportLoader"
      :width="$vuetify.breakpoint.xs ? 200 : 300"
      :height="$vuetify.breakpoint.xs ? 200 : 300"
    />
    <v-container fluid fill-height v-else-if="currentPageIndex !== null">
      <v-dialog
        v-model="showIncompleteAssessmentDialog"
        v-if="showIncompleteAssessmentDialog && !results.isCompleted"
        persistent
        max-width="700px"
      >
        <incomplete-assessment-dialog
          id="incompleteAssessmentDialog"
          v-model="showIncompleteAssessmentDialog"
          :incompleteAssessmentId="incompleteAssessmentId"
          @start="incompleteAssessmentDialogSetInProgress()"
        />
      </v-dialog>
      <v-dialog
        v-model="feedbackDialog"
        v-if="feedbackDialog"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
        persistent
      >
        <feedback-dialog
          v-model="feedbackDialog"
          :assessmentId="assessmentId"
        />
      </v-dialog>
      <v-dialog
        v-if="patientWelcomeDialog"
        v-model="patientWelcomeDialog"
        max-width="1500px"
        transition="dialog-bottom-transition"
      >
        <EndUserTour
          v-model="patientWelcomeDialog"
          :userId="userId"
          :assessmentId="assessmentId"
          :goalSet="!!results.userGoal"
          @updatedGoal="results.userGoal = $event"
        />
      </v-dialog>

      <v-btn
        v-show="displayPlant"
        x-small
        fixed
        bottom
        right
        disabled
        fab
        style="margin-bottom: 80px; margin-right: 50px"
      >
        <v-img
          src="@/assets/images/plantRight.png"
          contain
          height="200px"
        ></v-img>
      </v-btn>
      <feedback-button
        v-model="feedbackDialog"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
      />
      <short-feedback
        v-scroll="onScroll"
        v-model="quickFeedbackSubmitted"
        v-show="displayResultsFeedback"
        :assessmentId="assessmentId"
        style="margin-bottom: 125px; margin-right: 150px"
      />
      <v-row
        id="speechBubbleNextToStepperRow"
        class="align-self-start px-5 mt-0"
        align="center"
        justify="space-around"
        v-show="physioTopRight && !results.isCompleted"
      >
        <v-col cols="12" md="4">
          <desk-assessment-stepper
            :schema="schema"
            :currentTheme="currentTheme"
          />
        </v-col>
        <v-col cols="12" md="6" lg="5">
          <speech-bubble
            id="speechBubble"
            class="d-flex justify-center"
            :text="physioText"
            imageUrl="physioProfile.png"
          />
        </v-col>
      </v-row>
      <v-row
        id="speechBubbleBeneathStepperRow"
        class="align-self-start"
        justify="space-around"
        v-show="physioUnderStepper"
      >
        <v-col cols="12" md="6" lg="4">
          <desk-assessment-stepper
            :schema="schema"
            :currentTheme="currentTheme"
          />
          <speech-bubble
            id="speechBubble"
            class="d-flex justify-center mx-5"
            :text="physioText"
            imageUrl="physioProfile.png"
          />
          <v-img
            style="margin-top: 75px"
            v-show="currentPageIndex === 7"
            src="@/assets/images/undraw_working_out.svg"
            max-height="300px"
            contain
          ></v-img>
          <pain-person
            ref="painPerson"
            class="mt-12 sticky"
            v-if="!!results"
            v-show="currentTheme === 'pain'"
            v-model="results[pages[2].theme]"
            @genderChanged="saveGender($event)"
            :validateForm="validateForm"
            :linkedToQuestions="true"
            assessmentType="deskAssessment"
          />
          <v-img
            style="margin-top: 75px"
            v-show="currentPageIndex === 3"
            src="@/assets/images/undraw_in_the_office.svg"
            max-height="300px"
            contain
          ></v-img>
          <v-img
            style="margin-top: 75px"
            v-show="currentPageIndex === 4"
            src="@/assets/images/undraw_work_time.svg"
            max-height="300px"
            contain
          ></v-img>
          <v-img
            style="margin-top: 75px"
            v-show="currentPageIndex === 6"
            src="@/assets/images/undraw_multitasking.svg"
            max-height="300px"
            contain
          ></v-img>
        </v-col>

        <v-col cols="12" sm="10" md="6" class="pa-5">
          <health-confidentiality-card v-if="currentPageIndex === 8" />
          <v-form ref="form" onsubmit="return false;">
            <pain-question-panels
              ref="painAreaPanels"
              v-if="currentPageIndex === 2"
              :results="results"
              @removePainArea="$refs.painPerson.confirmRemovePainArea($event)"
            />
            <form-generator
              key="3"
              v-if="currentPageIndex === 3"
              :questions="pages[3].page.questions"
              :fullResults="results"
              v-model="results[pages[3].theme]"
            />
            <form-generator
              key="6"
              v-if="currentPageIndex === 6"
              :questions="pages[6].page.questions"
              v-model="results[pages[6].theme]"
              :answersToKeep="savedSimsValues"
            />
            <form-generator
              key="7"
              v-if="currentPageIndex === 7"
              :questions="pages[7].page.questions"
              v-model="results[pages[7].theme]"
            />
            <form-generator
              :key="componentKey"
              v-if="currentPageIndex === 8"
              :questions="pages[8].page.questions"
              :fullResults="results"
              v-model="results[pages[8].theme]"
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row class="pa-0 d-flex align-self-start">
        <desk-assessment-positioning
          v-show="currentPageIndex === 0"
          @allStepsChecked="setupStepsComplete = $event"
        />
        <initial-desk-measurements
          v-if="currentPageIndex === 1"
          :class="$vuetify.breakpoint.smAndDown ? 'pt-8' : ''"
          key="webcamResults"
          ref="initialDeskMeasurements"
          :snapshotTaken="snapshotTaken"
          @snapshot="results[pages[1].theme] = $event"
          @preventMeasure="preventMeasure = $event"
          @countdownRunning="countdownRunning = $event"
        />
        <sims
          v-if="currentPageIndex === 4"
          v-model="results[pages[4].theme]"
          :assessmentId="assessmentId"
          @updateSavedSimsValues="savedSimsValues = $event"
          @saveSimsVisualPlanner="saveSimsResults()"
        />

        <v-row v-if="currentPageIndex === 5" justify="center"
          ><v-col cols="12" sm="10" md="6" class="py-0 my-0"
            ><desk-height-visualiser :results="results"
          /></v-col>
          <v-col cols="12" sm="10" md="6" class="py-0 my-0">
            <v-form ref="heightQuestionForm" onsubmit="return false;">
              <form-generator
                :questions="pages[5].page.questions"
                v-model="results[pages[5].theme]"
              /> </v-form></v-col
        ></v-row>

        <report
          :results="results"
          v-if="currentPageIndex === 9"
          key="resultsPage"
          @page-navigation="currentPageIndex++"
          :assessmentId="assessmentId"
        />
        <initial-desk-measurements
          v-if="currentPageIndex === 10"
          key="fixWebcamResults"
          fixResults
        />
      </v-row>
      <v-row class="align-self-end">
        <desk-assessment-navigation-buttons
          v-if="!!results"
          @page-navigation="goToPage"
          @takeSnapshot="
            $refs['initialDeskMeasurements'].$refs['webcam'].startCountdown()
          "
          :currentPage="currentPageIndex"
          :numberOfPages="pages.length"
          :webcamCaptureComplete="snapshotTaken"
          :setupStepsComplete="setupStepsComplete"
          :results="results"
          :assessmentId="assessmentId"
          :preventMeasure="preventMeasure"
          :countdownRunning="countdownRunning"
          :savingData="savingData"
        />
      </v-row>
      <v-btn
        fab
        v-if="showResultsViewer"
        fixed
        bottom
        right
        @click="showQuickLoadAssessment = !showQuickLoadAssessment"
      >
        <v-icon>mdi-bug</v-icon>
      </v-btn>
      <v-dialog
        v-model="showQuickLoadAssessment"
        v-if="showQuickLoadAssessment"
        max-width="1500px"
      >
        <quick-load-assessment
          v-if="showQuickLoadAssessment && !!results"
          v-model="results"
          @report="currentPageIndex = 9"
          :validateForm="validateForm"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
const InitialDeskMeasurements = () =>
  import("@/components/deskassessment/InitialDeskMeasurements");
const Report = () => import("./report/Report.vue");
const FeedbackDialog = () =>
  import("@/components/common/Feedback/FeedbackDialog.vue");
const Sims = () => import("@/components/deskassessment/Sims.vue");
const DeskHeightVisualiser = () =>
  import("@/components/deskassessment/DeskHeightVisualiser");

import PainQuestionPanels from "@/components/deskassessment/PainQuestionPanels";
import DeskAssessmentNavigationButtons from "./DeskAssessmentNavigationButtons.vue";
import SpeechBubble from "@/components/common/SpeechBubble.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import FormGenerator from "@/components/form/FormGenerator.vue";
import DeskAssessmentPositioning from "@/components/deskassessment/DeskAssessmentPositioning";
import DeskAssessmentStepper from "@/components/common/Stepper.vue";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";
import WelcomeDialog from "@/components/common/WelcomeDialog.vue";
import { mapGetters } from "vuex";
import {
  storeAssessmentData,
  updateWellnessProgrammeWithPain,
  updateUserGoal,
  getInProgressAssessment,
  sendAssessmentCompleteNotificaiton
} from "@/customApi";
import DatapointGeneration from "@/services/assessment-results-datapoint-generation";
import { processAdminChecklist } from "@/services/onboarding/adminOnboarding";
import EndUserTour from "@/components/account/onboarding/EndUserTour";
import { EventBus } from "@/services/events/event-bus";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import DeskLoader from "@/components/common/loaders/DeskLoader.vue";
import HealthConfidentialityCard from "@/components/deskassessment/HealthConfidentialityCard.vue";
import SummaryService from "@/services/deskassessment/desk-assessment-summary.js";
import IncompleteAssessmentDialog from "@/components/common/IncompleteAssessmentDialog.vue";
import * as Constants from "@/constants/constants.js";
import QuickLoadAssessment from "./QuickLoadAssessment.vue";

import AssessmentLoadingService from "@/services/assessment-loading.js";

export default {
  name: "DeskAssessment",
  components: {
    DeskAssessmentNavigationButtons,
    SpeechBubble,
    PainPerson,
    FormGenerator,
    InitialDeskMeasurements,
    DeskAssessmentPositioning,
    DeskAssessmentStepper,
    Report,
    FeedbackDialog,
    EndUserTour,
    FeedbackButton,
    ShortFeedback,
    Sims,
    WelcomeDialog,
    DeskHeightVisualiser,
    DeskLoader,
    PainQuestionPanels,
    HealthConfidentialityCard,
    IncompleteAssessmentDialog,
    QuickLoadAssessment
  },
  props: {
    assessmentId: String,
    loadedResults: Object,
    origin: String
  },
  data() {
    return {
      componentKey: 0,
      currentPageIndex: null,
      schema: null,
      assessmentType: Constants.assessmentTypes.deskAssessment,
      feedbackDialog: false,
      nearBottomOfPage: false,
      setupStepsComplete: false,
      userInput: false,
      userClosedDialog: false,
      results: this.loadedResults,
      inWebcamView: true,
      preventMeasure: false,
      countdownRunning: false,
      userEmailSaved: false,
      savedSimsValues: [],
      showQuickLoadAssessment: false,
      signUpErrorMessage: null,
      savingData: false,
      pagesToSkip: [],
      assessmentLoading: true,
      reportLoading: false,
      loaderMinTime: 2000,
      loaderTimeLeft: 2000,
      timerInterval: null,
      quickFeedbackSubmitted: false,
      confirmPainDialog: false,
      incompleteAssessmentId: null,
      showIncompleteAssessmentDialog: false
    };
  },
  async mounted() {
    this.startAssessment();
  },
  created() {
    this.schema = require("@/assets/json/DeskAssessment/DeskAssessmentQuestions.json");

    if (this.loadedResults) {
      return;
    }

    // We need to pre-populate each key with an empty Object to allow multiple pages to bind to it
    this.results = this.schema.reduce(function (acc, item) {
      //added nesting to pain result so now there is a special case
      // where pain structure is not well captured by schema
      if (item.theme === "pain") {
        acc[item.theme] = { gender: "", areas: {} };
        return acc;
      }
      acc[item.theme] = {};
      return acc;
    }, {});
    this.results.notStarted = true;
  },
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "userEmail",
      "userIsInTeam",
      "signedIn",
      "userId",
      "hideHealthQuestions"
    ]),
    currentPainAreas() {
      return Object.keys(this.results["pain"].areas);
    },
    patientWelcomeDialog: {
      get() {
        if (this.assessmentLoading || this.currentPageIndex > 0) {
          return false;
        }
        const shareableLink = this.origin === "share";
        return shareableLink ||
          this.userClosedDialog ||
          this.signUpErrorMessage ||
          this.showIncompleteAssessmentDialog
          ? false
          : true;
      },
      set(dialogOpen) {
        this.userClosedDialog = !dialogOpen;
      }
    },
    hasCurrentPageIndex() {
      return this.currentPageIndex || this.currentPageIndex === 0;
    },
    currentTheme() {
      return this.hasCurrentPageIndex
        ? this.pages[this.currentPageIndex].theme
        : "";
    },
    currentSection() {
      return this.hasCurrentPageIndex
        ? this.pages[this.currentPageIndex].page.name
        : "";
    },
    pages() {
      // In order to iterate through pages more easily, provide a flat list of pages
      // (without theme grouping, but with an extra theme field since this is how we know where to store our results)
      return DeskAssessmentResults.getSchemaPages();
    },
    physioText() {
      if (this.currentPageIndex === 2) {
        let index = this.currentPainAreas.length > 0 ? 1 : 0;
        let text = this.$t(
          this.pages[this.currentPageIndex].page.speechBubbleText[index]
        );
        return text;
      }
      if (this.currentPageIndex == 4) {
        var indexToUse = this.isMobile() ? 1 : 0;
        return this.$t(
          this.pages[this.currentPageIndex].page.speechBubbleText[indexToUse]
        );
      }
      if (this.currentPageIndex == 6 && this.results.setup.standingdesk) {
        let index = 0;
        if (this.percentageSpentStanding >= 100) {
          index = 2;
        } else if (this.percentageSpentStanding > 60) {
          index = 1;
        }
        return this.$t(
          this.pages[this.currentPageIndex].page.speechBubbleText[index]
        );
      }
      if (this.currentPageIndex === 9) {
        return this.$t(
          this.pages[this.currentPageIndex].page.speechBubbleText[
            this.results.health.pregnancy === 1 ? 1 : 0
          ]
        );
      }
      if (!this.hasCurrentPageIndex) {
        return "";
      }

      return this.$t(
        this.pages[this.currentPageIndex].page.speechBubbleText[0]
      );
    },
    physioTopRight() {
      return (
        this.currentTheme === "webcam" ||
        this.currentTheme === "results" ||
        this.currentPageIndex == 4 ||
        this.currentPageIndex == 5
      );
    },
    physioUnderStepper() {
      return !this.physioTopRight;
    },
    disableNextButton() {
      return (
        this.currentPageIndex === 1 &&
        Object.keys(this.results.webcam).length === 0
      );
    },
    displayPlant() {
      if (
        this.currentPageIndex <= 1 ||
        this.currentPageIndex >= 9 ||
        this.currentPageIndex == 4
      ) {
        return false;
      }

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        default:
          return true;
      }
    },
    displayResultsFeedback() {
      return (
        this.currentPageIndex === 9 &&
        this.nearBottomOfPage &&
        !this.$vuetify.breakpoint.smAndDown &&
        !this.quickFeedbackSubmitted
      );
    },
    snapshotTaken() {
      return !!this.results[this.pages[1].theme]?.capturedImage || false;
    },
    percentageSpentStanding() {
      if (this.results.setup.standingdesk && this.results.setup.hours) {
        return Math.round(
          (this.results.setup.hoursstandingdesk / this.results.setup.hours) *
            100
        );
      }
      return 0;
    },
    showResultsViewer() {
      return (
        !!process.env.VUE_APP_REDIRECT_URL &&
        !process.env.VUE_APP_REDIRECT_URL.includes("vitruevida")
      );
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    let leavePage = true;
    if (
      this.results &&
      this.hasCurrentPageIndex &&
      this.currentPageIndex < 9 &&
      to.name != "ErrorPage"
    ) {
      if (this.userInput) {
        leavePage = window.confirm(
          this.$t("deskAssessment.leavePageConfirmation")
        );
      }
    }
    if (leavePage) {
      next();
    }
  },
  watch: {
    results: {
      handler() {
        this.updatePagesToSkip();

        if (
          this.currentPageIndex === 8 &&
          Object.keys(this.results.health).length === 1
        ) {
          return;
        }
        if (
          this.currentPageIndex === 3 &&
          Object.keys(this.results.setup).length === 1
        ) {
          return;
        }
        this.userInput = true;
      },
      deep: true
    },
    assessmentId(val) {
      if (val) {
        this.startAssessment();
      }
    },
    feedbackDialog: function (newVal) {
      if (newVal === true) {
        this.$gtag.event("Open regular feedback dialog from desk assessment", {
          event_category: "Feedback"
        });
      }
    }
  },
  methods: {
    async startAssessment() {
      this.assessmentLoading = true;
      await this.checkForInProgressAssessment();

      var allowInProgressToBeSet = !this.showIncompleteAssessmentDialog;
      let loadedAssessment =
        await AssessmentLoadingService.getAssessmentStateAndResults(
          this.assessmentId,
          this.assessmentType,
          allowInProgressToBeSet
        );

      if (loadedAssessment.redirectObject) {
        this.$router.push(loadedAssessment.redirectObject);
        return;
      }

      this.results = loadedAssessment.assessmentStarted
        ? loadedAssessment.results
        : this.results;
      this.currentPageIndex = loadedAssessment.assessmentStarted
        ? DeskAssessmentResults.getCurrentAssessmentIndex(this.results)
        : 0;

      this.updatePagesToSkip();
      this.componentKey += 1;
      this.assessmentLoading = false;
    },
    async checkForInProgressAssessment() {
      if (!this.signedIn) {
        return;
      }
      try {
        this.incompleteAssessmentId = await getInProgressAssessment(
          Constants.assessmentTypes.deskAssessment
        );
      } catch (err) {
      } finally {
        this.showIncompleteAssessmentDialog =
          !!this.incompleteAssessmentId &&
          this.incompleteAssessmentId !== this.assessmentId;
      }
    },
    async goToPage(newPageIndex) {
      hj("stateChange", this.$router.currentRoute.path + `/${newPageIndex}`);
      let index = newPageIndex;
      let navigatingBack = index < this.currentPageIndex;
      this.nearBottomOfPage = false;
      this.updatePagesToSkip();

      // when navigating backwards or away from pages without questions there is nothing to save
      if (navigatingBack || index == 1 || this.currentPageIndex === 9) {
        let newIndex = index;
        if (this.pagesToSkip.includes(index)) {
          newIndex = navigatingBack ? index - 1 : index + 1;
        }
        this.currentPageIndex = newIndex;
        this.userInput = false;
        return;
      }

      let validForm = false;
      if (this.currentPageIndex === 2) {
        validForm = await this.handlePainQuestionConfirmation();
      } else {
        validForm = this.validateForm();
      }

      if (this.currentPageIndex === 4 || validForm) {
        this.savingData = true;
        let assessmentId = this.assessmentId;
        let assessmentDataPointValues = [];

        if (
          this.currentPageIndex === 1 &&
          this.results.webcam.distanceFromScreen !== null
        ) {
          assessmentDataPointValues.push({
            AssessmentId: assessmentId,
            Name: "webcam",
            FieldType: "webcam",
            Value: {
              CapturedImage: null,
              DistanceFromScreen: this.results.webcam.distanceFromScreen,
              LeftShoulderToEarDistance:
                this.results.webcam.leftShoulderToEarDistance,
              RightShoulderHigher: this.results.webcam.rightShoulderHigher,
              RightShoulderToEarDistance:
                this.results.webcam.rightShoulderToEarDistance,
              RightShoulderToEarLonger:
                this.results.webcam.rightShoulderToEarLonger,
              ScreenHeightAngle: this.results.webcam.screenHeightAngle,
              ShoulderHeightDifference:
                this.results.webcam.shoulderHeightDifference,
              ShoulderToEarDifference:
                this.results.webcam.shoulderToEarDifference
            }
          });
        }

        // save pain area questions
        if (this.currentPageIndex === 2) {
          for (const [key, value] of Object.entries(this.results.pain.areas)) {
            for (const [key1, value1] of Object.entries(value)) {
              DatapointGeneration.getAssessmentDataPointValuesForPain(
                2,
                this.schema,
                key,
                key1,
                value1,
                assessmentDataPointValues
              );
            }
          }
        }

        if (this.currentPageIndex === 4) {
          this.generateSimsDataPoints(assessmentDataPointValues);
        }

        // save work setup questions
        // currently pages (4, 6, 7, 8)
        if (this.currentPageIndex >= 3 && this.currentPageIndex <= 7) {
          for (const [key, value] of Object.entries(this.results.setup)) {
            let pageKey =
              this.currentPageIndex === 3 ? 0 : this.currentPageIndex - 3;
            DatapointGeneration.getAssessmentDataPointValues(
              3,
              this.schema,
              pageKey,
              key,
              value,
              assessmentDataPointValues,
              null
            );
          }
        }

        // save health questions
        if (this.currentPageIndex === 8) {
          for (const [key, value] of Object.entries(this.results.health)) {
            DatapointGeneration.getAssessmentDataPointValuesForHealth(
              4,
              this.schema,
              key,
              value,
              assessmentDataPointValues,
              this.results
            );
          }
        }

        let markAssessmentAsCompleted = this.hideHealthQuestions
          ? this.currentPageIndex === 7
          : this.currentPageIndex === 8;
        let assessmentDataPointDto = {
          AssessmentId: this.assessmentId,
          AssessmentSection: this.currentSection,
          MarkAssessmentAsCompleted: markAssessmentAsCompleted,
          Values: assessmentDataPointValues,
          AssessmentType: this.assessmentType
        };

        // save data to db & navigate if successful
        this.reportLoading = markAssessmentAsCompleted;
        if (this.reportLoading) {
          this.startLoader();
        }

        storeAssessmentData(assessmentDataPointDto)
          .then(() => {
            let newIndex = index;
            if (this.pagesToSkip.includes(index)) {
              newIndex = navigatingBack ? index - 1 : index + 1;
            }
            this.currentPageIndex = newIndex;
          })
          .catch(err => {})
          .finally(() => {
            this.savingData = false;
            if (markAssessmentAsCompleted) {
              this.$mixpanel.track("Desk assessment complete");
              this.stopLoader();
              if (!this.results.userGoal) {
                this.saveSuggestedUserGoal();
              }
              var painAreasDto = SummaryService.GetRankedPainAreaDtosOrDefault(
                this.results
              );
              updateWellnessProgrammeWithPain(this.assessmentId, painAreasDto);
              sendAssessmentCompleteNotificaiton(this.assessmentId);
              if (this.signedIn) {
                processAdminChecklist("selfAssessment");
              }
            }
          });

        this.userInput = false;
      }
    },
    async handlePainQuestionConfirmation() {
      // check all pain questions are valid and validate form
      if (
        this.validateForm() &&
        this.$refs.painAreaPanels?.allAreasComplete()
      ) {
        return true;
      }
      // open all incomplete panels if form validation failed on pain area
      this.$refs.painAreaPanels?.showIncompletePanels();
      this.validateForm();
      return false;
    },
    startLoader() {
      this.timerInterval = setInterval(() => (this.loaderTimeLeft -= 10), 10);
    },
    async stopLoader() {
      clearInterval(this.timerInterval);
      if (this.loaderTimeLeft > 0) {
        await new Promise(resolve => setTimeout(resolve, this.loaderTimeLeft));
      }
      this.loaderTimeLeft = this.loaderMinTime;
      this.reportLoading = false;
      if (this.signedIn) {
        hj("stateChange", this.$router.currentRoute.path + `/deskreport`);
      }
    },
    validateForm() {
      if (this.currentPageIndex === 5) {
        return this.$refs.heightQuestionForm.validate();
      }
      let valid = this.$refs.form.validate();
      if (!valid) {
        EventBus.$emit("validateCustomFormComponents");
      }
      return valid;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      this.nearBottomOfPage =
        window.innerHeight + window.pageYOffset >=
        e.target.body.offsetHeight - 500;
    },
    preventNav() {
      if (
        this.hasCurrentPageIndex &&
        this.currentPageIndex < 9 &&
        this.currentPageIndex !== 4
      ) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    saveGender(value) {
      this.$gtag.event("Gender changed for pain map", {
        event_category: "Desk Assessment - Pain Page"
      });
      this.results.pain.gender = value;
    },
    updatePagesToSkip() {
      this.pagesToSkip = [];

      // hide desk height question if either workarea incorrect.
      if (this.results.setup && this.results.setup.workarea > 1) {
        this.pagesToSkip.push(5);
      }

      if (this.hideHealthQuestions) {
        this.pagesToSkip.push(8);
      }
    },
    async saveSimsResults() {
      let dataPointValues = [];
      this.generateSimsDataPoints(dataPointValues);

      let assessmentDataPointDto = {
        AssessmentId: this.assessmentId,
        AssessmentSection: this.currentSection,
        Values: dataPointValues,
        AssessmentType: this.assessmentType
      };

      await storeAssessmentData(assessmentDataPointDto);
    },
    generateSimsDataPoints(dataPointValues) {
      DatapointGeneration.addDeskPlanObjectsToData(
        this.results[this.pages[4].theme].deskItems,
        dataPointValues,
        "deskItems"
      );

      if (this.results[this.pages[4].theme].deskImageUrl) {
        DatapointGeneration.addDeskPlanImageUrlToData(
          this.results[this.pages[4].theme].deskImageUrl,
          dataPointValues,
          "deskItems"
        );
      }
    },
    async saveSuggestedUserGoal() {
      try {
        let suggestedGoal = SummaryService.GetSuggestedUserGoal(this.results);
        let dto = {
          userId: this.userId,
          goal: suggestedGoal.stringValue,
          isDefault: true,
          assessmentId: this.assessmentId
        };

        await updateUserGoal(dto);
      } catch (ex) {
        this.$logger.captureException(ex);
      }
    },
    async incompleteAssessmentDialogSetInProgress() {
      await AssessmentLoadingService.setAssessmentToInProgress(
        this.assessmentId,
        this.assessmentType
      );
    }
  }
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Required for Safari */
  position: sticky;
  top: 0px;
}
</style>
