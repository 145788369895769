var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _vm.reportLoading
        ? _c("desk-loader", {
            attrs: {
              id: "reportLoader",
              width: _vm.$vuetify.breakpoint.xs ? 200 : 300,
              height: _vm.$vuetify.breakpoint.xs ? 200 : 300
            }
          })
        : _vm.currentPageIndex !== null
        ? _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _vm.showIncompleteAssessmentDialog && !_vm.results.isCompleted
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "700px" },
                      model: {
                        value: _vm.showIncompleteAssessmentDialog,
                        callback: function($$v) {
                          _vm.showIncompleteAssessmentDialog = $$v
                        },
                        expression: "showIncompleteAssessmentDialog"
                      }
                    },
                    [
                      _c("incomplete-assessment-dialog", {
                        attrs: {
                          id: "incompleteAssessmentDialog",
                          incompleteAssessmentId: _vm.incompleteAssessmentId
                        },
                        on: {
                          start: function($event) {
                            return _vm.incompleteAssessmentDialogSetInProgress()
                          }
                        },
                        model: {
                          value: _vm.showIncompleteAssessmentDialog,
                          callback: function($$v) {
                            _vm.showIncompleteAssessmentDialog = $$v
                          },
                          expression: "showIncompleteAssessmentDialog"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.feedbackDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": _vm.$vuetify.breakpoint.smAndDown
                          ? "100%"
                          : "40%",
                        persistent: ""
                      },
                      model: {
                        value: _vm.feedbackDialog,
                        callback: function($$v) {
                          _vm.feedbackDialog = $$v
                        },
                        expression: "feedbackDialog"
                      }
                    },
                    [
                      _c("feedback-dialog", {
                        attrs: { assessmentId: _vm.assessmentId },
                        model: {
                          value: _vm.feedbackDialog,
                          callback: function($$v) {
                            _vm.feedbackDialog = $$v
                          },
                          expression: "feedbackDialog"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.patientWelcomeDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "1500px",
                        transition: "dialog-bottom-transition"
                      },
                      model: {
                        value: _vm.patientWelcomeDialog,
                        callback: function($$v) {
                          _vm.patientWelcomeDialog = $$v
                        },
                        expression: "patientWelcomeDialog"
                      }
                    },
                    [
                      _c("EndUserTour", {
                        attrs: {
                          userId: _vm.userId,
                          assessmentId: _vm.assessmentId,
                          goalSet: !!_vm.results.userGoal
                        },
                        on: {
                          updatedGoal: function($event) {
                            _vm.results.userGoal = $event
                          }
                        },
                        model: {
                          value: _vm.patientWelcomeDialog,
                          callback: function($$v) {
                            _vm.patientWelcomeDialog = $$v
                          },
                          expression: "patientWelcomeDialog"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.displayPlant,
                      expression: "displayPlant"
                    }
                  ],
                  staticStyle: {
                    "margin-bottom": "80px",
                    "margin-right": "50px"
                  },
                  attrs: {
                    "x-small": "",
                    fixed: "",
                    bottom: "",
                    right: "",
                    disabled: "",
                    fab: ""
                  }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/images/plantRight.png"),
                      contain: "",
                      height: "200px"
                    }
                  })
                ],
                1
              ),
              _c("feedback-button", {
                style: _vm.$vuetify.breakpoint.smAndDown
                  ? ""
                  : "top:50%;right: 0%",
                model: {
                  value: _vm.feedbackDialog,
                  callback: function($$v) {
                    _vm.feedbackDialog = $$v
                  },
                  expression: "feedbackDialog"
                }
              }),
              _c("short-feedback", {
                directives: [
                  {
                    name: "scroll",
                    rawName: "v-scroll",
                    value: _vm.onScroll,
                    expression: "onScroll"
                  },
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.displayResultsFeedback,
                    expression: "displayResultsFeedback"
                  }
                ],
                staticStyle: {
                  "margin-bottom": "125px",
                  "margin-right": "150px"
                },
                attrs: { assessmentId: _vm.assessmentId },
                model: {
                  value: _vm.quickFeedbackSubmitted,
                  callback: function($$v) {
                    _vm.quickFeedbackSubmitted = $$v
                  },
                  expression: "quickFeedbackSubmitted"
                }
              }),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.physioTopRight && !_vm.results.isCompleted,
                      expression: "physioTopRight && !results.isCompleted"
                    }
                  ],
                  staticClass: "align-self-start px-5 mt-0",
                  attrs: {
                    id: "speechBubbleNextToStepperRow",
                    align: "center",
                    justify: "space-around"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("desk-assessment-stepper", {
                        attrs: {
                          schema: _vm.schema,
                          currentTheme: _vm.currentTheme
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "5" } },
                    [
                      _c("speech-bubble", {
                        staticClass: "d-flex justify-center",
                        attrs: {
                          id: "speechBubble",
                          text: _vm.physioText,
                          imageUrl: "physioProfile.png"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.physioUnderStepper,
                      expression: "physioUnderStepper"
                    }
                  ],
                  staticClass: "align-self-start",
                  attrs: {
                    id: "speechBubbleBeneathStepperRow",
                    justify: "space-around"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "4" } },
                    [
                      _c("desk-assessment-stepper", {
                        attrs: {
                          schema: _vm.schema,
                          currentTheme: _vm.currentTheme
                        }
                      }),
                      _c("speech-bubble", {
                        staticClass: "d-flex justify-center mx-5",
                        attrs: {
                          id: "speechBubble",
                          text: _vm.physioText,
                          imageUrl: "physioProfile.png"
                        }
                      }),
                      _c("v-img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentPageIndex === 7,
                            expression: "currentPageIndex === 7"
                          }
                        ],
                        staticStyle: { "margin-top": "75px" },
                        attrs: {
                          src: require("@/assets/images/undraw_working_out.svg"),
                          "max-height": "300px",
                          contain: ""
                        }
                      }),
                      !!_vm.results
                        ? _c("pain-person", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.currentTheme === "pain",
                                expression: "currentTheme === 'pain'"
                              }
                            ],
                            ref: "painPerson",
                            staticClass: "mt-12 sticky",
                            attrs: {
                              validateForm: _vm.validateForm,
                              linkedToQuestions: true,
                              assessmentType: "deskAssessment"
                            },
                            on: {
                              genderChanged: function($event) {
                                return _vm.saveGender($event)
                              }
                            },
                            model: {
                              value: _vm.results[_vm.pages[2].theme],
                              callback: function($$v) {
                                _vm.$set(_vm.results, _vm.pages[2].theme, $$v)
                              },
                              expression: "results[pages[2].theme]"
                            }
                          })
                        : _vm._e(),
                      _c("v-img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentPageIndex === 3,
                            expression: "currentPageIndex === 3"
                          }
                        ],
                        staticStyle: { "margin-top": "75px" },
                        attrs: {
                          src: require("@/assets/images/undraw_in_the_office.svg"),
                          "max-height": "300px",
                          contain: ""
                        }
                      }),
                      _c("v-img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentPageIndex === 4,
                            expression: "currentPageIndex === 4"
                          }
                        ],
                        staticStyle: { "margin-top": "75px" },
                        attrs: {
                          src: require("@/assets/images/undraw_work_time.svg"),
                          "max-height": "300px",
                          contain: ""
                        }
                      }),
                      _c("v-img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentPageIndex === 6,
                            expression: "currentPageIndex === 6"
                          }
                        ],
                        staticStyle: { "margin-top": "75px" },
                        attrs: {
                          src: require("@/assets/images/undraw_multitasking.svg"),
                          "max-height": "300px",
                          contain: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-5",
                      attrs: { cols: "12", sm: "10", md: "6" }
                    },
                    [
                      _vm.currentPageIndex === 8
                        ? _c("health-confidentiality-card")
                        : _vm._e(),
                      _c(
                        "v-form",
                        { ref: "form", attrs: { onsubmit: "return false;" } },
                        [
                          _vm.currentPageIndex === 2
                            ? _c("pain-question-panels", {
                                ref: "painAreaPanels",
                                attrs: { results: _vm.results },
                                on: {
                                  removePainArea: function($event) {
                                    return _vm.$refs.painPerson.confirmRemovePainArea(
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.currentPageIndex === 3
                            ? _c("form-generator", {
                                key: "3",
                                attrs: {
                                  questions: _vm.pages[3].page.questions,
                                  fullResults: _vm.results
                                },
                                model: {
                                  value: _vm.results[_vm.pages[3].theme],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.results,
                                      _vm.pages[3].theme,
                                      $$v
                                    )
                                  },
                                  expression: "results[pages[3].theme]"
                                }
                              })
                            : _vm._e(),
                          _vm.currentPageIndex === 6
                            ? _c("form-generator", {
                                key: "6",
                                attrs: {
                                  questions: _vm.pages[6].page.questions,
                                  answersToKeep: _vm.savedSimsValues
                                },
                                model: {
                                  value: _vm.results[_vm.pages[6].theme],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.results,
                                      _vm.pages[6].theme,
                                      $$v
                                    )
                                  },
                                  expression: "results[pages[6].theme]"
                                }
                              })
                            : _vm._e(),
                          _vm.currentPageIndex === 7
                            ? _c("form-generator", {
                                key: "7",
                                attrs: {
                                  questions: _vm.pages[7].page.questions
                                },
                                model: {
                                  value: _vm.results[_vm.pages[7].theme],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.results,
                                      _vm.pages[7].theme,
                                      $$v
                                    )
                                  },
                                  expression: "results[pages[7].theme]"
                                }
                              })
                            : _vm._e(),
                          _vm.currentPageIndex === 8
                            ? _c("form-generator", {
                                key: _vm.componentKey,
                                attrs: {
                                  questions: _vm.pages[8].page.questions,
                                  fullResults: _vm.results
                                },
                                model: {
                                  value: _vm.results[_vm.pages[8].theme],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.results,
                                      _vm.pages[8].theme,
                                      $$v
                                    )
                                  },
                                  expression: "results[pages[8].theme]"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-0 d-flex align-self-start" },
                [
                  _c("desk-assessment-positioning", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentPageIndex === 0,
                        expression: "currentPageIndex === 0"
                      }
                    ],
                    on: {
                      allStepsChecked: function($event) {
                        _vm.setupStepsComplete = $event
                      }
                    }
                  }),
                  _vm.currentPageIndex === 1
                    ? _c("initial-desk-measurements", {
                        key: "webcamResults",
                        ref: "initialDeskMeasurements",
                        class: _vm.$vuetify.breakpoint.smAndDown ? "pt-8" : "",
                        attrs: { snapshotTaken: _vm.snapshotTaken },
                        on: {
                          snapshot: function($event) {
                            _vm.results[_vm.pages[1].theme] = $event
                          },
                          preventMeasure: function($event) {
                            _vm.preventMeasure = $event
                          },
                          countdownRunning: function($event) {
                            _vm.countdownRunning = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.currentPageIndex === 4
                    ? _c("sims", {
                        attrs: { assessmentId: _vm.assessmentId },
                        on: {
                          updateSavedSimsValues: function($event) {
                            _vm.savedSimsValues = $event
                          },
                          saveSimsVisualPlanner: function($event) {
                            return _vm.saveSimsResults()
                          }
                        },
                        model: {
                          value: _vm.results[_vm.pages[4].theme],
                          callback: function($$v) {
                            _vm.$set(_vm.results, _vm.pages[4].theme, $$v)
                          },
                          expression: "results[pages[4].theme]"
                        }
                      })
                    : _vm._e(),
                  _vm.currentPageIndex === 5
                    ? _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", sm: "10", md: "6" }
                            },
                            [
                              _c("desk-height-visualiser", {
                                attrs: { results: _vm.results }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { cols: "12", sm: "10", md: "6" }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "heightQuestionForm",
                                  attrs: { onsubmit: "return false;" }
                                },
                                [
                                  _c("form-generator", {
                                    attrs: {
                                      questions: _vm.pages[5].page.questions
                                    },
                                    model: {
                                      value: _vm.results[_vm.pages[5].theme],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.results,
                                          _vm.pages[5].theme,
                                          $$v
                                        )
                                      },
                                      expression: "results[pages[5].theme]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentPageIndex === 9
                    ? _c("report", {
                        key: "resultsPage",
                        attrs: {
                          results: _vm.results,
                          assessmentId: _vm.assessmentId
                        },
                        on: {
                          "page-navigation": function($event) {
                            _vm.currentPageIndex++
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.currentPageIndex === 10
                    ? _c("initial-desk-measurements", {
                        key: "fixWebcamResults",
                        attrs: { fixResults: "" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "align-self-end" },
                [
                  !!_vm.results
                    ? _c("desk-assessment-navigation-buttons", {
                        attrs: {
                          currentPage: _vm.currentPageIndex,
                          numberOfPages: _vm.pages.length,
                          webcamCaptureComplete: _vm.snapshotTaken,
                          setupStepsComplete: _vm.setupStepsComplete,
                          results: _vm.results,
                          assessmentId: _vm.assessmentId,
                          preventMeasure: _vm.preventMeasure,
                          countdownRunning: _vm.countdownRunning,
                          savingData: _vm.savingData
                        },
                        on: {
                          "page-navigation": _vm.goToPage,
                          takeSnapshot: function($event) {
                            return _vm.$refs["initialDeskMeasurements"].$refs[
                              "webcam"
                            ].startCountdown()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.showResultsViewer
                ? _c(
                    "v-btn",
                    {
                      attrs: { fab: "", fixed: "", bottom: "", right: "" },
                      on: {
                        click: function($event) {
                          _vm.showQuickLoadAssessment = !_vm.showQuickLoadAssessment
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-bug")])],
                    1
                  )
                : _vm._e(),
              _vm.showQuickLoadAssessment
                ? _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "1500px" },
                      model: {
                        value: _vm.showQuickLoadAssessment,
                        callback: function($$v) {
                          _vm.showQuickLoadAssessment = $$v
                        },
                        expression: "showQuickLoadAssessment"
                      }
                    },
                    [
                      _vm.showQuickLoadAssessment && !!_vm.results
                        ? _c("quick-load-assessment", {
                            attrs: { validateForm: _vm.validateForm },
                            on: {
                              report: function($event) {
                                _vm.currentPageIndex = 9
                              }
                            },
                            model: {
                              value: _vm.results,
                              callback: function($$v) {
                                _vm.results = $$v
                              },
                              expression: "results"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }