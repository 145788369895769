<template>
  <v-container fluid style="width:90%">
    <v-row justify="space-around">
      <v-col cols="12" lg="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-4"
            :elevation="hover ? 16 : 2"
            @click="stepClicked(0)"
            :color="checkedStages[0] ? 'selectedPrimary' : 'transparent'"
            outlined
            :style="
              'height:100%; border: 2px solid ' +
                getBorderColor(checkedStages[0]) +
                '!important'
            "
          >
            <v-row justify="center" class="container ma-0">
              <v-img
                src="@/assets/images/webcamOnScreen.png"
                height="200px"
                contain
              />
              <v-checkbox
                class="topRightFloat"
                v-model="checkedStages[0]"
                @click="stepClicked(0)"
              ></v-checkbox>
            </v-row>
            <div class="text-h4 text-center vitrueGrey-color">
              {{ $t("deskAssessment.webcam.positioning.step1Title") }}
            </div>
            <p class="text-body-2 text-center px-3 py-2">
              {{ $t("deskAssessment.webcam.positioning.step1") }}
            </p>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-4"
            :elevation="hover ? 16 : 2"
            @click="stepClicked(1)"
            :color="checkedStages[1] ? 'selectedPrimary' : 'transparent'"
            outlined
            :style="
              'height:100%; border: 2px solid ' +
                getBorderColor(checkedStages[1]) +
                '!important'
            "
          >
            <v-row justify="center" class="container ma-0">
              <v-img
                src="@/assets/images/90degreesScreen.png"
                height="200px"
                contain
              />
              <v-checkbox
                class="topRightFloat"
                v-model="checkedStages[1]"
                @click="stepClicked(1)"
              ></v-checkbox>
            </v-row>
            <p class="text-h4 text-center vitrueGrey-color">
              {{ $t("deskAssessment.webcam.positioning.step2Title") }}
            </p>
            <p class="text-body-2 text-center px-3">
              {{ $t("deskAssessment.webcam.positioning.step2") }}
            </p>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-4"
            :elevation="hover ? 16 : 2"
            @click="stepClicked(2)"
            :color="checkedStages[2] ? 'selectedPrimary' : 'transparent'"
            outlined
            :style="
              'height:100%; border: 2px solid ' +
                getBorderColor(checkedStages[2]) +
                '!important'
            "
          >
            <v-row justify="center" class="container ma-0">
              <v-img
                src="@/assets/images/sittingPosition.png"
                height="200px"
                contain
              />
              <v-checkbox
                class="topRightFloat"
                v-model="checkedStages[2]"
                @click="stepClicked(2)"
              ></v-checkbox>
            </v-row>
            <p class="text-h4 text-center vitrueGrey-color">
              {{ $t("deskAssessment.webcam.positioning.step3Title") }}
            </p>
            <p class="text-body-2 text-center px-3">
              {{ $t("deskAssessment.webcam.positioning.step3") }}
            </p>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
export default {
  name: "DeskAssessmentPositioning",
  data() {
    return {
      checkedStages: [false, false, false]
    };
  },
  methods: {
    getBorderColor(isChecked) {
      return isChecked ? "#63a8ff" : "gray";
    },
    stepClicked(index) {
      Vue.set(this.checkedStages, index, !this.checkedStages[index]);
      if (!this.checkedStages.includes(false)) {
        this.$emit("allStepsChecked", true);
      } else {
        this.$emit("allStepsChecked", false);
      }
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
}
.topRightFloat {
  position: absolute;
  right: 0;
  top: 0;
}
.vitrueGrey-color {
  color: var(--v-vitrueGrey-base);
}
</style>
