<template>
  <v-container>
    <v-dialog
      v-if="sendReportDialog"
      v-model="sendReportDialog"
      :max-width="sendReportDialogSize"
    >
      <SendReportDialog
        v-model="sendReportDialog"
        :assessmentID="assessmentId"
        assessmentType="Desk assessment"
        @inputClose="sendReportDialog = false"
      />
    </v-dialog>

    <v-row justify="center">
      <v-col id="backButtonContainer" sm="6" md="2" v-show="showBack">
        <v-btn
          id="backButton"
          color="primary"
          large
          outlined
          rounded
          width="100%"
          @click="back"
          >{{ $t("buttons.back") }}</v-btn
        >
      </v-col>
      <!-- <page-indicator v-if="index === 2" v-model="setupStep" :total="3"></page-indicator> -->

      <v-col id="nextButtonContainer" sm="6" md="2" v-show="showNext">
        <v-btn
          id="nextButton"
          v-if="currentPage > 0 && !showMeasureButton"
          :loading="savingData"
          color="primary"
          large
          rounded
          width="100%"
          @click="next"
          >{{ $t("buttons.next") }}</v-btn
        >
        <v-btn
          id="startButton"
          v-if="currentPageIsFirstPage"
          :disabled="disableStart"
          color="primary"
          large
          rounded
          width="100%"
          @click="next"
          >{{ $t("buttons.start") }}</v-btn
        >
        <v-btn
          v-if="showMeasureButton"
          :disabled="disableMeasure"
          color="primary"
          large
          rounded
          width="100%"
          @click="measure"
          >{{ $t("buttons.measure") }}</v-btn
        >
        <v-btn
          v-show="showSkip"
          color="primary"
          text
          large
          width="100%"
          @click="skip"
          >{{ $t("buttons.skip") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SendReportDialog from "@/components/deskassessment/report/SendReportDialog";
import { mapGetters } from "vuex";

export default {
  name: "desk-assessment-navigation-buttons",
  components: {
    SendReportDialog
  },
  props: {
    numberOfPages: Number,
    currentPage: Number,
    webcamCaptureComplete: Boolean,
    setupStepsComplete: Boolean,
    results: {
      type: Object
    },
    assessmentId: String,
    preventMeasure: Boolean,
    countdownRunning: Boolean,
    signUpErrorMessage: String,
    savingData: Boolean
  },
  data() {
    return {
      skippedPages: 0,
      webcamPageIndex: 1,
      sendReportDialog: false,
      signUpUser: false,
      emailReportCheckbox: false,
      reportSent: false
    };
  },
  computed: {
    showBack() {
      return (
        !this.currentPageIsFirstPage &&
        !this.currentPageIsReportPage &&
        !this.results.isCompleted
      );
    },
    showNext() {
      return (
        !this.currentPageIsReportPage &&
        this.currentPage < this.numberOfPages - 1
      );
    },
    showSkip() {
      return (
        (this.currentPageIsWebcamPage ||
          this.currentPageIsWebcamInstructionsPage) &&
        !this.webcamCaptureComplete
      );
    },
    showEmail() {
      if (!this.results) {
        return false;
      }
      return this.currentPageIsReportPage && !this.results.isCompleted;
    },
    showMeasureButton() {
      return this.currentPageIsWebcamPage && !this.webcamCaptureComplete;
    },
    disableStart() {
      return (
        this.currentPageIsWebcamInstructionsPage && !this.setupStepsComplete
      );
    },
    disableMeasure() {
      let outOfViewInWebcam =
        this.currentPageIsWebcamPage &&
        this.preventMeasure &&
        !this.webcamCaptureComplete;
      return this.countdownRunning || outOfViewInWebcam;
    },
    currentPageIsWebcamPage() {
      return this.currentPage === this.webcamPageIndex;
    },
    currentPageIsWebcamInstructionsPage() {
      return this.currentPage === this.webcamPageIndex - 1;
    },
    currentPageIsFirstPage() {
      return this.currentPage === 0;
    },
    currentPageIsReportPage() {
      return this.currentPage === this.numberOfPages - 2;
    },
    sendReportDialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        return "60%";
      }
      return "30%";
    }
  },
  methods: {
    back() {
      if (
        this.skippedPages > 0 &&
        this.currentPage === this.webcamPageIndex + 1
      ) {
        this.$emit("page-navigation", this.currentPage - this.skippedPages);
        this.skippedPages = 0;
      } else {
        this.$emit("page-navigation", this.currentPage - 1);
      }
      this.$vuetify.goTo(0);
    },
    next() {
      this.$emit("page-navigation", this.currentPage + 1);
      this.$vuetify.goTo(0);
    },
    measure() {
      this.$emit("takeSnapshot");
    },
    skip() {
      this.skippedPages = this.currentPageIsWebcamPage ? 1 : 2;
      this.$emit("page-navigation", this.currentPage + this.skippedPages);
      this.$vuetify.goTo(0);
    }
  },
  watch: {
    signUpUser: function (newVal) {
      if (newVal === true) {
        this.$gtag.event("Sign up dialog - opened", {
          event_category: "Desk Assessment - Report"
        });
      }
    }
  }
};
</script>
